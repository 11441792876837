<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-title>Serail Search</v-list-item-title>
      </v-list-item>
    </template>
    <v-card raised class="mx-auto">
      <v-toolbar dense flat color="yellow lighten-1">
        <v-toolbar-title class="subtitle-1"
          ><span>Serial Search In {{ getCustomer.name }}</span>
          <span class="ml-2" v-if="productStatus">
            <v-chip
              small
              :color="productStatus.status ? 'red' : 'teal'"
              text-color="white"
            >
              {{ !productStatus.status ? "In Stock" : "Out of Stock" }}
            </v-chip>
          </span>
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn small icon @click.stop.prevent="close">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <ModelTitle title="Serial Search" @close="close" /> -->

      <v-card-text>
        <v-row class="mt-5" justify="space-between">
          <v-col>
            <v-text-field
              @focus="$event.target.select()"
              label="Serial Number"
              hide-details
              outlined
              autofocus
              prepend-inner-icon="search"
              clearable
              dense
              rounded
              @keyup.enter="loadImeiNumber()"
              v-model="returnImeiNumber"
              ref="returnImeiNumber"
            >
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="success" @click="loadImeiNumber()">Search</v-btn>
          </v-col>
        </v-row>
        <v-card-text v-if="product" class="subtitle-1">
          <span>Name: </span>
          <span class="font-weight-bold">{{ productStatus.name }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-data-table
          dense
          :loading="textLoading"
          :headers="headers"
          sort-by="date"
          :sort-desc="false"
          :itemsPerPage="InvoiceDetail.length"
          hide-default-footer
          :items="InvoiceDetail"
        >
          <template v-slot:item="{ item }">
            <tr :style="{ 'background-color': color(item) }">
              <td class="text-left">
                <a href="#" @click="view(item)">{{ item.localId }}</a>
              </td>
              <td class="text-left">
                {{ item.date | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-capitalize">
                {{ item.name }}
              </td>
              <td class="text-right">
                <span v-if="item.type === 'Purchase'"> ******** </span>
                <span v-else>{{ item.price | currency }}</span>
              </td>
              <td>
                {{ item.type }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider> </v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn text color="primary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import invoiceService from "@/modules/Invoice/service";
import purchaseService from "@/modules/Inventory/Purchase/service";
import Hashids from "hashids";
const hashids = new Hashids();
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      purchase: null,
      product: null,
      btnColor: "info",
      label: "Restock",
      InvoiceDetail: [],
      productStatus: null,
      textLoading: false,
      dialog: false,
      returnImeiNumber: null,
      headers: [
        {
          text: "ID",
          align: "left",
          value: "Invoice.date",
          sortable: true,
        },

        {
          text: "DATE",
          align: "left",
          value: "date",
          sortable: true,
        },

        {
          text: "NAME",
          align: "center",
          value: "qty",
          sortable: true,
        },
        {
          text: "PRICE",
          align: "right",
          value: "totalsale",
          sortable: true,
        },
        {
          text: "TYPE",
          align: "left",
          value: "name",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    openSerialSearch(val) {
      this.dialog = val;
      if (val) {
        setTimeout(() => {
          this.$refs.returnImeiNumber.focus();
        }, 100);
      }
    },
  },
  computed: {
    ...mapGetters("layout", ["getOpenSerialSearch"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    ...mapGetters("customer", ["getCustomer"]),
    openSerialSearch: {
      get: function () {
        return this.getOpenSerialSearch;
      },
      set: function (val) {
        this.setOpenSerialSearch(val);
      },
    },
  },
  methods: {
    ...mapActions("layout", ["setOpenSerialSearch"]),
    async restock() {
      if (!this.returnImeiNumber) {
        this.$swal("Restock", "Item was restock", "warning");
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: `You want to restock serial number: ${this.returnImeiNumber}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          await purchaseService
            .restock({ imeiNumber: this.returnImeiNumber })
            .then(() => {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Item was restock",
                });
              this.close();
            });
        }
      });
    },
    getStatus() {
      return purchaseService
        .search({ imeiNumber: this.returnImeiNumber })
        .then((response) => {
          // console.log("getStatus", response);
          this.product = response.data;
          if (this.product.length !== 0) {
            // if (this.product[0].qty > 0) {
            this.productStatus = this.product[0];
            // }
            if (this.productStatus && this.productStatus.status) {
              this.label = "Restock";
              this.btnColor = "info";
            } else {
              this.label = "Mark it sold";
              this.btnColor = "orange";
            }
          }
        });
    },
    color(item) {
      let color = "white";

      if (item.type === "Purchase") color = "#E1BEE7";
      if (item.type === "Return To Vendor") color = "#BBDEFB";
      if (item.type === "Sold") color = "#A5D6A7";
      if (item.type === "Return") color = "#FF7043";
      if (item.type === "Restock") color = "#29B6F6";
      if (item.type === "Mark It Sold") color = "#FFEE58";

      return color;
    },
    reset() {
      this.InvoiceDetail = [];
      this.product = null;
      this.label = "Restock";
      this.btnColor = "info";
      this.productStatus = null;
    },
    close() {
      this.reset();
      this.returnImeiNumber = null;
      this.dialog = false;
    },
    view(item) {
      if (item.type === "Sold" || item.type === "Return") {
        this.viewInvoice(item.id);
      }
      if (item.type === "Purchase" || item.type === "Return To Vendor") {
        this.viewPurchase(item.id, this.productStatus.imeiNumber);
      }
    },
    viewInvoice(id) {
      const hashId = hashids.encode(id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPurchase(id, search) {
      const hashId = hashids.encode(id);
      const routeData = this.$router.resolve({
        path: `/global/purchase/${hashId}/packingSlip?serialNumber=${search}`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    async loadImeiNumber() {
      this.reset();
      this.textLoading = true;
      await this.getStatus();
      if (this.productStatus) {
        await this.getPurchase();
        await this.getInvoice();
        await this.getRestock();
      }
      this.textLoading = false;
    },
    getInvoice() {
      invoiceService
        .getAllInvoiceDetail({ imeiNumber: this.returnImeiNumber })
        .then((response) => {
          // console.log("invoiceDetail", response);
          const result = response.data;
          if (result.length > 0) {
            result.map((row) => {
              this.InvoiceDetail.push({
                localId: row.Invoice.localId,
                id: row.InvoiceId,
                date: row.Invoice.date,
                name: row.Invoice.Customer.name,
                price: row.price,
                type: row.qty === 1 ? "Sold" : "Return",
              });
            });
          } else {
            // console.log("dfasdf");
            this.findLostIMEInumber();
          }
        });
    },
    findLostIMEInumber() {
      if (this.purchase) {
        // console.log("findLostIMEINumber", this.purchase);
        invoiceService
          .findLostIMEINumber({
            ProductId: this.purchase.ProductId,
            CustomerId: this.getCustomer.id,
            imeiNumber: this.returnImeiNumber,
          })
          .then((response) => {
            // console.log("findLostIMEINumber", response);
            const result = response.data;
            if (result) {
              this.InvoiceDetail.push({
                localId: result.Invoice.localId,
                id: result.InvoiceId,
                date: result.Invoice.date,
                name: this.getCustomer.name,
                price: result.price,
                type: result.qty === 1 ? "Sold" : "Return",
              });
            }
          });
      }
    },
    getRestock() {
      purchaseService
        .getRestock({ imeiNumber: this.returnImeiNumber })
        .then((response) => {
          // console.log("getRestock", response);
          const result = response.data;
          if (result.length > 0) {
            result.map((row) => {
              this.InvoiceDetail.push({
                localId: " ",
                id: " ",
                date: row.createdAt,
                name: "",
                price: 0,
                type: row.type ? row.type : "Restock",
              });
            });
          }
        });
    },
    getPurchase() {
      purchaseService
        .getPurchaseDetail({ imeiNumber: this.returnImeiNumber })
        .then((response) => {
          // console.log("PurchaseIMEIResult", response);
          const result = response.data;
          this.purchase = result;
          if (result.length > 0) {
            result.map((row) => {
              this.InvoiceDetail.push({
                localId: row.Purchase.localId,
                id: row.PurchaseId,
                date: row.Purchase.date,
                name: "**********",
                price: row.cost,
                type: row.qty === 1 ? "Purchase" : "Return To Vendor",
              });
            });
          }
          this.PurchaseIMEIResult = result;
          this.textLoading = false;
          if (this.PurchaseIMEIResult) this.found = true;
        });
    },
  },
};
</script>

<style scoped></style>
